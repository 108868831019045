// modified version of https://github.com/NV/flying-focus

import { offset } from '../helper/utils';

export default function FlyingFocus(activeFocusTrap) {
  const DURATION = 300;
  const MOBILMEDIA = window.matchMedia('(max-width: 839px)');
  const doc = document;
  const docElem = doc.documentElement;
  const { body } = doc;
  const isMobile = docElem.classList.contains('is-mobile');
  const header = doc.querySelector('#header');
  const main = doc.querySelector('main');
  let ringElem = null;
  let movingId = 0;
  let prevFocused = null;
  let keyDownTime = 0;
  let prevX = 0;
  let prevY = 0;
  let lastScrollY = 0;
  let lastKeyEvent = null;

  function onEnd() {
    if (!movingId) {
      return;
    }

    clearTimeout(movingId);
    movingId = 0;
    ringElem.className = '';
    prevFocused.classList.remove('flying-focus_target');
    // lastKeyEvent = null;
    // prevFocused = null;
  }

  function isJustPressed() {
    return Date.now() - keyDownTime < 300;
  }

  function initialize() {
    ringElem = doc.createElement('flying-focus');
    ringElem.id = 'flying-focus';
    ringElem.style.transitionDuration = `${DURATION / 1000}s`;
    body.appendChild(ringElem);
  }

  docElem.addEventListener('keydown', (e) => {
    const { key } = e;

    // Show animation only upon Tab or Arrow keys press.
    if (key === 'Tab' || (key === 'ArrowDown' || key === 'ArrowUp')) {
      keyDownTime = Date.now();
      lastKeyEvent = e;
    }
  }, false);

  docElem.addEventListener('focus', (e) => {
    // skip for mobile
    if (isMobile && MOBILMEDIA.matches) {
      return;
    }

    let { target } = e;

    if (target.id === 'flying-focus') {
      return;
    }

    // only allow focus on valid elements if focus is currently limited to certain elements
    // eg. navigation is open, modal visible, etc.
    if (activeFocusTrap.elements) {
      let valid = false;
      let prevIndex = -1;

      for (let i = 0; i < activeFocusTrap.elements.length; i++) {
        const el = activeFocusTrap.elements[i];

        if (el === prevFocused) {
          prevIndex = i;
        }

        // focused element is valid
        if (el === target) {
          valid = true;
          break;
        }
      }

      // element isn't allowed to be focused, focus next or previous valid element
      if (valid === false) {
        const indent = lastKeyEvent?.shiftKey === true && lastKeyEvent.key === 'Tab' ? -1 : 1;
        const nextIndex = prevIndex + indent === activeFocusTrap.elements.length - 1 ? 0 : prevIndex + indent;

        // browser autoscrolls to invalid focus target, which makes the animation look weird
        // updating scroll position to last valid focused element fixes that
        window.scrollTo(0, lastScrollY);
        activeFocusTrap.elements[nextIndex].focus();
        return;
      }
    }

    let isFirstFocus = false;
    if (!ringElem) {
      isFirstFocus = true;
      initialize();
    }

    // having nav-links autofocused isn't possible from design perspective, but to still have correct focus their
    // parent get's focused, which then makes the flying-focus look weird on next focus
    // to counter that the target get's set on a nav-link, so that it's dimensions will be used on next focus
    if (target.classList.contains('subnav') || target.classList.contains('main-nav')) {
      target = target.querySelector('.nav-link');
    }

    // get all element dimensions and postions
    const current = ringElem.getBoundingClientRect();
    const currentOffset = offset(ringElem);
    const next = target.getBoundingClientRect();
    const targetOffset = offset(target);
    let top = Math.round(targetOffset.top);
    let left = Math.round(targetOffset.left);
    let targetWidth = Math.round(next.width);
    let targetHeight = Math.round(next.height);

    // modify dimesions and postions for elements where defaults don't quite fit
    if (target.className.indexOf('btn') !== -1) {
      top -= 1;
      left -= 1;
      targetWidth += 7;
      targetHeight += 7;
    } else if (target.classList.contains('tag')) {
      top -= 2;
      left -= 2;
      targetWidth += 6;
      targetHeight += 6;
    } else if (target.classList.contains('card')) {
      if (target.className.indexOf('pattern-bg') === -1) {
        targetWidth += 7;
      }
    } else if (target.classList.contains('logo')) {
      targetWidth -= 0.5;
    } else if (target.classList.contains('tab-link')) {
      top += 4;
      targetHeight -= 2;
    } else if (target.classList.contains('accordion-toggle')) {
      top -= 1;
      targetHeight += 3;
    } else if (target.parentElement.classList.contains('bc-nav')) {
      targetHeight -= 1;
    } else if (target.classList.contains('bbq-nav-link')) {
      targetHeight -= 1;
    } else if (
      target.nodeName === 'A'
      && main.contains(target)
      && target.classList.contains('animals-card') === false
    ) {
      top += 4;
    }

    // set target position
    ringElem.style.left = `${left}px`;
    ringElem.style.top = `${top}px`;
    ringElem.style.width = `${targetWidth}px`;
    ringElem.style.height = `${targetHeight}px`;

    // calculate postion from new to previous
    const invertx = prevX && currentOffset.left - left;
    const inverty = prevY && currentOffset.top - top;
    const invertsx = current.width === 0 ? 1 : current.width / targetWidth;
    const invertsy = current.height === 0 ? 1 : current.height / targetHeight;

    ringElem.style.opacity = '0';
    ringElem.style.transitionProperty = 'none';
    ringElem.style.transform = `matrix(${invertsx}, 0, 0, ${invertsy}, ${invertx}, ${inverty})`;
    // console.log({
    //   transform: `matrix(${invertsx}, 0, 0, ${invertsy}, ${invertx}, ${inverty})`,
    //   currentOffset,
    //   targetOffset,
    // });

    prevX = left;
    prevY = top;

    // wait 2 frames as animation won't work otherwise
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        ringElem.style.transitionProperty = 'transform';
        ringElem.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';

        // waiting for next frame fixes element flicker
        requestAnimationFrame(() => {
          ringElem.style.opacity = '1';
        });
      });
    });

    if (isFirstFocus || !isJustPressed()) {
      prevFocused = target;
      return;
    }

    onEnd();
    target.classList.add('flying-focus_target');
    ringElem.classList.add('flying-focus_visible');

    // apply classes for elements with special styling
    if (target.className.indexOf('btn') !== -1) {
      ringElem.classList.add('focus-btn');
    } else if (target.classList.contains('custom-select-option')) {
      ringElem.classList.add('focus-option');
    } else if (target.classList.contains('radio')) {
      ringElem.classList.add('focus-radio');
    } else if (target.classList.contains('switch-checkbox')) {
      ringElem.classList.add('focus-switch');
    } else if (target.classList.contains('tooltip') && target.classList.contains('form-icon')) {
      ringElem.classList.add('focus-formicon-tooltip');
    } else if (target.classList.contains('tag')) {
      ringElem.classList.add('focus-tag');
    } else if (target.parentElement.classList.contains('autoComplete_result')) {
      ringElem.classList.add('search-result');
    } else if (header?.classList.contains(target)) {
      ringElem.classList.add('focus-header');
    } else if (target.parentElement.classList.contains('bc-nav')) {
      ringElem.classList.add('focus-bc-nav');
    } else if (target.classList.contains('bbq-nav-link')) {
      ringElem.classList.add('focus-bbq-nav-link');
    }

    prevFocused = target;
    lastScrollY = window.scrollY;
    movingId = setTimeout(onEnd, DURATION);
  }, true);

  docElem.addEventListener('blur', () => {
    onEnd();
  }, true);

  const style = doc.createElement('style');
  style.textContent = `#flying-focus {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    background: transparent;
    visibility: hidden;
    pointer-events: none;
    outline: 2px solid #222;
    outline: 2px solid var(--theme-default);
    outline-offset: 2px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0,1,0,1);
    transform-origin: 0 0;
    will-change: transform;
  }
  #flying-focus.flying-focus_visible {
    visibility: visible;
    z-index: 9999;
  }
  .flying-focus_target {
    outline: none !important; /* Doesn't work in Firefox :( */
  }
  /* http://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links/199319 */
  .flying-focus_target::-moz-focus-inner {
    border: 0 !important;
  }`;

  body.appendChild(style);
}
