import { $, $$, delegate } from '../helper/utils';
import { MOBILMEDIA } from '../helper/constants';
// import focusLoop from '../helper/focusLoop';

const isMobile = document.documentElement.classList.contains('is-mobile');
const showMenuBtn = $('.show-menu');
const nav = $('.desktop-nav');
const mainNav = $('.main-nav');
const mainLinks = $$('.main-nav .nav-link');
const subnavList = $('.subnav-list');
const subnavBorder = $('.subnav-list > .snb');
const subnavBack = $('.subnav-back');
const navWrapper = $('.nav-wrapper');
const navBar1 = $('.show-menu .bar1');
const navBar2 = $('.show-menu .bar2');
const navBar3 = $('.show-menu .bar3');
const border = $('#header .hb');
const navFooter = $('.nav-footer');
const state = {
  navOpen: false,
  subnavVisible: false,
  activeLink: null,
  activeSubnav: null,
};
// eslint-disable-next-line max-len
const focusableElements = $$('.desktop-nav .nav-link, #header a[href], #header button, #header input, .main-nav, .subnav');
let scrollTop;

// focus subnav on right arrow key press
function focusSubnav(e) {
  if (e.key === 'ArrowRight') {
    e.preventDefault();
    state.activeSubnav.querySelector('.nav-link').focus();
  }
}

export default function navigation(activeFocusTrap) {
  if (showMenuBtn === null) {
    return;
  }

  const mobileSubnavTl = gsap.timeline({
    paused: true,
    onReverseComplete() {
      // reset timeline and inline styles
      mobileSubnavTl.clear();
      gsap.set(subnavBack, { clearProps: 'all' });
      gsap.set(state.activeLink, { clearProps: 'transform' });

      // update local state
      state.activeLink = null;
    },
  });

  // main timeline showing navigation
  const timeline = gsap.timeline({
    paused: true,
    onStart() {
      nav.dispatchEvent(new CustomEvent('navOpen'));
    },
    onComplete() {
      // update local state
      state.navOpen = true;

      // set focusable elements, preventing focus of outside elements (used in flying-focus.js)
      activeFocusTrap.elements = focusableElements;

      // make navigation visible to voice assistants
      nav.setAttribute('aria-hidden', false);

      // focus active nav item, or just mainnav if not a mobile device
      if (isMobile === false) {
        if (state.activeLink !== null) {
          state.activeLink.focus();
        } else {
          mainNav.focus();
        }
      }

      // show subnav if active and not in mobile view
      if (MOBILMEDIA.matches === false && state.subnavVisible) {
        gsap.to(subnavList, {
          duration: 0.175,
          autoAlpha: 1,
          ease: 'sine.out',
        });
      }
    },
    onReverseComplete() {
      state.navOpen = false;
      nav.setAttribute('aria-hidden', true);
      document.body.classList.remove('nav-open');
      activeFocusTrap.elements = null;
    },
  });

  // add tweens
  timeline
    .set(nav, {
      visibility: 'inherit',
    })
    .to(border, {
      duration: 0.225,
      xPercent: 100,
      ease: 'sine.in',
    })
    .fromTo(border, {
      xPercent: -100,
    }, {
      delay: 0.125,
      duration: 0.275,
      xPercent: 0,
      ease: 'sine.out',
      immediateRender: false,
    })
    .to(navBar2, {
      duration: 0.1,
      scaleX: 0,
      ease: 'sine.in',
    }, '-=0.625')
    .to(navBar1, {
      duration: 0.1,
      y: 9,
      ease: 'sine.out',
    }, '<')
    .to(navBar3, {
      duration: 0.1,
      y: -9,
      ease: 'sine.out',
    }, '<')
    .to(navBar1, {
      duration: 0.125,
      rotation: 45,
      scaleX: 1,
      y: 10,
      ease: 'sine.out',
    }, '-=0.525')
    .to(navBar3, {
      duration: 0.125,
      rotation: -45,
      scaleX: 1,
      y: -12,
      ease: 'sine.out',
    }, '-=0.525')
    .to(navWrapper, {
      duration: 0.225,
      opacity: 1,
      ease: 'sine.out',
    }, '-=0.475')
    .to(mainLinks, {
      duration: 0.175,
      opacity: 1,
      ease: 'sine.out',
      stagger: 0.1,
    }, '-=0.225');

  // open nav on menu button click
  showMenuBtn.addEventListener('click', () => {
    // ignore click while tweening
    if (timeline.totalProgress() > 0 && timeline.totalProgress() < 1) {
      return;
    }

    // close nav if already open
    if (state.navOpen === true) {
      // reenable body scroll and set scroll position to last previous
      document.documentElement.classList.remove('noscroll');
      window.scrollTo(0, scrollTop);

      requestAnimationFrame(() => {
        // hide subnav
        if (MOBILMEDIA.matches) {
          mobileSubnavTl.totalProgress(0);
          mobileSubnavTl.clear();
        } else if (state.subnavVisible) {
          gsap.to(subnavList, {
            duration: 0.125,
            autoAlpha: 0,
            ease: 'sine.in',
          });
        }

        // make reverse animations faster
        timeline.timeScale(1.25);
        timeline.reverse();
        nav.dispatchEvent(new CustomEvent('navClose'));
      });

      return;
    }

    // get current vertical scroll position to revert to after closing nav (mostly for mobile)
    scrollTop = window.scrollY;

    // prevent body scroll
    document.documentElement.classList.add('noscroll');

    document.body.classList.add('nav-open');

    requestAnimationFrame(() => {
      // reset timeline speed to default and start
      timeline.timeScale(1);
      timeline.play();
    });
  }, { passive: true });

  // mobile view only, hide subnav on back button click at top of subnav
  function closeMobileSubnav() {
    // ignore click while tweening
    if (mobileSubnavTl.totalProgress() > 0 && mobileSubnavTl.totalProgress() < 1) {
      return false;
    }

    gsap.set(subnavBack, { pointerEvents: 'none' });
    state.activeSubnav.setAttribute('aria-hidden', true);
    state.activeSubnav = null;
    mobileSubnavTl.timeScale(1.25);
    return mobileSubnavTl.reverse();
  }
  subnavBack.addEventListener('click', closeMobileSubnav, { passive: true });

  // handle the slide in/out of subnavs
  function handleSubnav(e) {
    if (e instanceof Event) {
      e.preventDefault();
    }

    const { subnav } = this.dataset;

    if (MOBILMEDIA.matches) {
      // ignore click while tweening
      if (mobileSubnavTl.totalProgress() > 0 && mobileSubnavTl.totalProgress() < 1) {
        return;
      }

      const siblings = [...this.parentElement.children].filter((sl) => sl !== this);
      state.activeLink = this;
      state.activeSubnav = $(subnav);
      state.activeSubnav.setAttribute('aria-hidden', false);

      // clear subnav inline styles
      gsap.set(state.activeSubnav, { clearProps: 'all' });

      // get start postion and dimensions
      const start = this.getBoundingClientRect();

      // get end postion and dimensions
      const end = state.activeSubnav.querySelector('.subnav-title').getBoundingClientRect();

      // calculate difference between and and start position
      const targetX = end.left - start.left;
      const targetY = end.top - start.top;

      // calculate a factor to determine tween duration depending on distance of start and end position
      const factor = Math.round(Math.abs(targetY / start.height));

      console.log({ end, start });

      mobileSubnavTl
        .set(subnavList, {
          visibility: 'inherit',
          clearProps: 'opacity',
        })
        .to(siblings, {
          duration: 0.175,
          autoAlpha: 0,
          ease: 'sine.in',
        })
        .to(navFooter, {
          duration: 0.175,
          autoAlpha: 0,
          ease: 'sine.in',
        }, '<')
        .to(this, {
          duration: 0.15 + (0.025 * factor),
          x: targetX,
          y: targetY,
          ease: 'sine.out',
        })
        .to(subnavBack, {
          duration: 0.175,
          opacity: 1,
          ease: 'sine.in',
        })
        .set(state.activeSubnav, {
          height: 'auto',
          visibility: 'inherit',
        }, '<')
        .fromTo(state.activeSubnav.querySelectorAll('.nav-link'), { y: -10 }, {
          duration: 0.175,
          opacity: 1,
          y: 0,
          ease: 'sine.out',
          stagger: 0.1,
        }, '<');

      // reset timeline speed to default and start
      mobileSubnavTl.timeScale(1);
      mobileSubnavTl.play();
    } else {
      // same as open, completly hide subnav
      // eslint-disable-next-line no-lonely-if
      if (state.activeLink === this) {
        state.activeLink.removeEventListener('keydown', focusSubnav);
        state.activeLink.classList.remove('open');
        state.activeSubnav.setAttribute('aria-hidden', true);
        state.subnavVisible = false;
        state.activeSubnav = null;
        state.activeLink = null;

        requestAnimationFrame(() => {
          // hide active subnav
          gsap.to(subnav, {
            duration: 0.2,
            display: 'none',
            opacity: 0,
            ease: 'sine.in',
          });

          // hide subnav border
          gsap.to(subnavBorder, {
            delay: 0.1,
            duration: 0.2,
            scaleY: 0,
            ease: 'sine.in',
          });
        });

        // different then open, hide visible subnav and show new one
      } else if (state.subnavVisible) {
        state.activeLink.removeEventListener('keydown', focusSubnav);
        state.activeLink.classList.remove('open');
        state.activeLink = this;
        state.activeSubnav.setAttribute('aria-hidden', true);
        this.classList.add('open');
        this.addEventListener('keydown', focusSubnav);

        requestAnimationFrame(() => {
          // hide previous subnav
          gsap.to(state.activeSubnav, {
            duration: 0.2,
            display: 'none',
            opacity: 0,
            ease: 'sine.in',
          });

          state.activeSubnav = $(subnav);
          state.activeSubnav.setAttribute('aria-hidden', false);

          // show new subnav
          gsap.to(subnav, {
            duration: 0.225,
            delay: 0.225,
            display: 'block',
            opacity: 1,
            ease: 'sine.out',
            onComplete() {
              // focus first subnav link if not mobile
              if (isMobile === false) {
                state.activeSubnav.focus();
              }
            },
          });
        });

        // none open, show subnav
      } else {
        this.classList.add('open');
        this.addEventListener('keydown', focusSubnav);
        state.activeLink = this;
        state.subnavVisible = true;
        state.activeSubnav = $(subnav);
        state.activeSubnav.setAttribute('aria-hidden', false);

        requestAnimationFrame(() => {
          // show subanv border
          gsap.to(subnavBorder, {
            duration: 0.225,
            scaleY: 1,
            ease: 'sine.out',
          });

          // show subanv
          gsap.to(subnav, {
            duration: 0.225,
            delay: 0.05,
            display: 'block',
            opacity: 1,
            ease: 'sine.out',
            onComplete() {
              // focus first subnav link if not mobile
              if (isMobile === false) {
                state.activeSubnav.focus();
              }
            },
          });
        });
      }
    }
  }
  delegate(navWrapper, 'click', '.nav-link[data-subnav]', handleSubnav);

  // open subnav if a top level nav-link is active on page load, but only if not in mobile view
  const initialActiveNav = $('.nav-link.active[data-subnav]');
  if (initialActiveNav !== null && MOBILMEDIA.matches === false) {
    handleSubnav.call(initialActiveNav);
    gsap.set(subnavList, {
      autoAlpha: 0,
      delay: 1,
    });
  }

  // keyboard focus handling
  navWrapper.addEventListener('keydown', (e) => {
    const parent = e.target.parentElement;

    // focus next nav link
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.target.nextElementSibling?.focus();

      // focus previous nav link
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prev = e.target.previousElementSibling;

      // only set focus if prev item is a nav link (first item is the mobile subnav dummy title element)
      if (prev?.classList.contains('nav-link')) {
        prev.focus();
      }

      // set focus to active main nav link if focus is on first subnav nav link
      // and Shift+Tab or just left arrow key was pressed
    } else if (
      (
        parent.classList.contains('subnav')
        && parent.querySelector('.nav-link') === e.target
        && e.key === 'Tab' && e.shiftKey === true
      )
      || e.key === 'ArrowLeft'
    ) {
      e.preventDefault();
      state.activeLink.focus();
    }
  });

  MOBILMEDIA.addListener((e) => {
    const { activeLink, activeSubnav } = state;

    // is mobile view
    if (e.matches) {
      console.log([activeLink, activeSubnav]);
      // close active subnav and reset state
      if (activeSubnav) {
        gsap.set([activeSubnav, subnavBorder], { clearProps: 'all' });
        state.activeLink.removeEventListener('keydown', focusSubnav);
        state.activeLink.classList.remove('open');
        state.activeSubnav.setAttribute('aria-hidden', true);
        state.subnavVisible = false;
        state.activeSubnav = null;
        state.activeLink = null;

        if (state.navOpen === true) {
          handleSubnav.call(activeLink);
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (activeSubnav) {
        // close mobile subnav, clear tween styles and then reopen subnav
        closeMobileSubnav()
          .then(() => {
            setTimeout(() => {
              gsap.set([subnavList, activeSubnav, ...activeSubnav.children], { clearProps: 'all' });
              handleSubnav.call(activeLink);
            }, 50);
          });
      } else {
        // clear inline styles
        gsap.set([
          subnavList,
          ...subnavList.children,
          ...subnavList.querySelectorAll('.nav-link'),
        ], { clearProps: 'all' });

        if (initialActiveNav !== null) {
          handleSubnav.call(initialActiveNav);
          gsap.set(subnavList, {
            autoAlpha: 0,
            delay: 1,
          });
        }
      }
    }
  });
}
