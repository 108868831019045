const XLMEDIA = window.matchMedia('(min-width: 1280px)');
const DESKTOPMEDIA = window.matchMedia('(min-width: 1024px)');
const TABLETMEDIA = window.matchMedia('(min-width: 600px) and (max-width: 1023px)');
const MOBILMEDIA = window.matchMedia('(max-width: 839px)');
const PHONEMEDIA = window.matchMedia('(max-width: 599px)');

export {
  DESKTOPMEDIA,
  MOBILMEDIA,
  TABLETMEDIA,
  PHONEMEDIA,
  XLMEDIA,
};
