/* eslint-disable */

// based on http://www.paciellogroup.com/blog/2012/04/how-to-remove-css-outlines-in-an-accessible-manner/
export default function () {
  const styleElement = document.createElement('STYLE');
  const domEvents = 'addEventListener' in document;
  const addEventListener = (type, callback) => {
    // Basic cross-browser event handling
    if (domEvents) {
      document.addEventListener(type, callback);
    } else {
      document.attachEvent(`on${type}`, callback);
    }
  };
  const setCss = (css_text) => {
    // Handle setting of <style> element contents in IE8
    !!styleElement.styleSheet ? styleElement.styleSheet.cssText = css_text : styleElement.innerHTML = css_text;
  };

  document.head.appendChild(styleElement);

  // Using mousedown instead of mouseover, so that previously focused elements don't lose focus ring on mouse move
  addEventListener('mousedown', () => {
    setCss(':focus,.form-control,input.toggle + label:before, .switch-label, .card:before, .accordion-toggle:before, [class*="btn"]:after, .review-rating-stars > input:focus + label,a:before,.link-button:before{outline:0!important;}input.radio + label:before,.switch-label,.tooltip,.tag:before{box-shadow:none!important;}::-moz-focus-inner{border:0!important;}');
  });

  addEventListener('keydown', (e) => {
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].indexOf(e.key) !== -1) {
      setCss('');
    }
  });
}
