/**
 * Execute a function once an element is entering the viewport
 *
 * @param {NodeList} elements The elements to observe
 * @param {function} callback The callback function to execute
 * @param {Object={}} overrideOptions Options for the IntersectionObserver
 * @return {void}
 */
export default (elements, callback, paraOptions = { observer: {}, unobserve: true, removeAll: true }) => {
  if (elements === null || elements.length === 0) {
    console.info('No elements found');
    return;
  }

  if (callback == null || typeof callback !== 'function') {
    console.warn('No callback function provided');
    return;
  }

  const options = {
    ...paraOptions,
    ...{
      root: null,
      rootMargin: '0px',
      threshold: 0,
      ...paraOptions.observer,
    },
  };

  // observer function that get's called when an element intersects the viewport
  const observer = new IntersectionObserver((entries) => {
    // get all visible entries
    const targets = [];

    for (let i = 0; i < entries.length; i++) {
      const el = entries[i];

      if (el.isIntersecting === true) {
        const { target } = el;
        targets.push(target);

        if (options.unobserve) {
          // remove observer from element
          observer.unobserve(target);
        }

        if (options.removeAll === true) {
          for (let j = 0; j < elements.length; j++) {
            observer.unobserve(elements[j]);
          }

          break;
        }
      }
    }

    if (targets.length > 0) {
      callback(targets);
    }
  }, options.observer);

  // setup overserver for each element
  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];
    observer.observe(el);
  }
};
